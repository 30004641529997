<template>
  <div class="form-group w-100" :class="{ error: error }"> 
    <label class="input-label form-label"
      >{{ label }}&nbsp;<span v-if="required" style="color: red">*</span>
    </label>
    <div class="input-group" v-if="isLeftIcon || isRightIcon">
      <div class="input-group-prepend" v-if="isLeftIcon">
        <span class="input-group-text"
          ><ElementsIcon v-bind:icon="leftIconName"
        /></span>
      </div>
      <flat-pickr
        ref="flatpickr"
        v-model="internalValue"
        :config="datePickerConfig"
        @onChange="handleChange"
        :disabled="isDisabled"
        @onClose="handleClose"
        :placeholder="$t('Select Date')"
      />
      <div class="input-group-append" v-if="isRightIcon">
        <span class="input-group-text"
          ><ElementsIcon v-bind:icon="rightIconName"
        /></span>
      </div>
    </div>
    <flat-pickr
      v-else
      ref="flatpickr"
      v-model="internalValue"
      :disabled="isDisabled"
      :config="datePickerConfig"
      @onChange="handleChange"
      @onClose="handleClose"
      :placeholder="$t('Select Date')"
    />
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { english } from "flatpickr/dist/l10n/default.js";
import { French } from "flatpickr/dist/l10n/fr.js";
import { German } from "flatpickr/dist/l10n/de.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import { Arabic } from "flatpickr/dist/l10n/ar.js";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import { Mandarin as Chinese } from "flatpickr/dist/l10n/zh.js";
import ElementsIcon from "@/components/elements/Icon.vue";
export default {
  components: { flatPickr ,ElementsIcon},
  props: {
    isLeftIcon: { type: Boolean, default: false },
    isRightIcon: { type: Boolean, default: false },
    leftIconName: { type: String, default: null },
    rightIconName: { type: String, default: null },
    required: { type: Boolean, required: false },
    isDisabled: {type: Boolean, required: false},
    error: String,
    label: String,
    value: {
      type: [String, Date, Array],
      default: null,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      internalValue: this.value,
      currentLocale: this.getSavedLocale(),
    };
  },
  computed: {
    datePickerConfig() {
      return {
        ...this.options,
        locale: this.getFlatpickrLocale(this.currentLocale),
        onOpen: this.handleOpen,
        onClose: this.handleClose,
        onChange: this.handleChange,
      };
    },
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
    },
    internalValue(newValue) {
      if (newValue !== this.value) {
        this.$emit("input", newValue);
      }
    },
  },
  methods: {
    getSavedLocale() {
      const savedLocale = localStorage.getItem("lang");
      return savedLocale &&
        ["en", "fr", "de", "es", "ja", "ar", "tu", "ch"].includes(savedLocale)
        ? savedLocale
        : "en";
    },
    getFlatpickrLocale(locale) {
      switch (locale) {
        case "en":
          return english;
        case "fr":
          return French;
        case "de":
          return German;
        case "es":
          return Spanish;
        case "ja":
          return Japanese;
        case "ar":
          return Arabic;
        case "tu":
          return Turkish;
        case "ch":
          return Chinese;
        default:
          return english;
      }
    },
    handleOpen() {
      const savedLocale = localStorage.getItem("lang");
      if (savedLocale && savedLocale !== this.currentLocale) {
        this.currentLocale = savedLocale;
        this.updateFlatpickrLocale();
      }
      this.$emit("open");
    },
    handleChange(selectedDates) {
      this.$nextTick(() => {
        this.$emit("change", selectedDates);
      });
    },
    handleClose() {
      this.$emit("close");
    },
    updateFlatpickrLocale() {
      if (this.$refs.flatpickr) {
        this.$refs.flatpickr.fp.set(
          "locale",
          this.getFlatpickrLocale(this.currentLocale)
        );
      }
    },
  },
};
</script>
